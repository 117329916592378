<template>
  <v-responsive :aspect-ratio="41 / 35">
    <div class="video-block">
      <v-responsive :aspect-ratio="16 / 9">
        <transition name="fade">
          <div class="welcome-holder fill-height">
            <transition name="fade">
              <img
                src="@/assets/show-poster.png"
                width="100%"
                v-show="!howitworks"
                alt="Whodunnit at the Coliseum show poster"
                class="welcome-img"
                :class="{ hover: starthover }"
              />
            </transition>
            <transition name="fade">
              <img
                src="@/assets/how-it-works.png"
                v-show="howitworks"
                width="100%"
                alt="Whodunnit at the Coliseum show poster"
              />
            </transition>
          </div>
        </transition>
      </v-responsive>
    </div>
    <div class="options">
      <v-responsive :aspect-ratio="5 / 1">
        <v-container fill-height>
          <v-row justify="center" class="fill-height">
            <v-col cols="6">
              <div
                class="pa-2 option left"
                :class="{ loaded: changetored }"
                @mouseenter="starthover = true"
                @mouseleave="starthover = false"
                @click="$emit('start')"
                tabindex="0"
                role="button"
              >
                <span>Start the Show</span>
              </div>
            </v-col>
            <v-col cols="6">
              <div
                class="pa-2 option right"
                @click="howitworks = !howitworks"
                :class="{ selected: howitworks, loaded: changetored }"
                tabindex="0"
                role="button"
              >
                <span>How it Works</span>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </div>
  </v-responsive>
</template>

<script>
export default {
  data: function() {
    return {
      starthover: false,
      howitworks: false,
      changetored: false
    };
  },
  props: ["loaded"],
  watch: {
    loaded(val) {
      if (val) {
        setTimeout(() => {
          this.changetored = true;
        }, 6000);
      }
    },
    howitworks(val) {
      if (val) {
        this.$gtag.event("how_it_works_clicked");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
main {
  .video-block {
    position: relative;
    top: 7%;
    margin: 7px 7px 7px 14px;
  }
}
.welcome-holder {
  box-sizing: border-box;
  position: relative;
  width: 100%;

  img {
    position: absolute;
    left: 0;
    top: 0;
  }

  .welcome-img {
    filter: grayscale(0.8) brightness(1.5) contrast(1.1);
    -webkit-filter: grayscale(0.8) brightness(1.5) contrast(1.1);
    transition: 0.8s;

    &.hover {
      filter: grayscale(0) brightness(1) contrast(1);
      -webkit-filter: grayscale(0) brightness(1) contrast(1);
    }
  }
}

.options {
  position: relative;
  transition: 1s ease-out;
  top: 4%;
  padding-top: 0.6vh;
  text-align: center;
  padding-top: 30px;

  .option {
    overflow: hidden;
    font-family: "Franchise";
    background: url(../assets/btn-left-black.png) no-repeat;
    background-size: 100% 100%;
    width: calc(100% - 30px);
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6vw;
    transition: 0.2s ease-out;
    opacity: 1;
    position: relative;
    margin: 0 20px;
    box-sizing: border-box;
    text-align: center;
    line-height: 1.4vw;
    cursor: pointer !important;
    transition: 0.8s;
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    opacity: 0.6;

    span {
      color: #fefa69;
    }

    &.left {
      transition: 1s ease-out;

      &.loaded {
        background-image: url(../assets/btn-left-red.png);
        filter: grayscale(0);
        -webkit-filter: grayscale(0);
        opacity: 1;
      }
    }

    &.right {
      background-image: url(../assets/btn-right-black.png);

      &.loaded {
        filter: grayscale(0);
        -webkit-filter: grayscale(0);
        opacity: 1;
      }
    }

    &:hover {
      opacity: 0.9;
    }

    &.selected {
      opacity: 1;
      background-image: url(../assets/btn-right-red.png);
      span {
        opacity: 1;
        color: #fefa69;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: 0.8s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
